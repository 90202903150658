<template>
  <div class="container-fluid">
    <div class="row d-flex justify-space-between mr-5">
      <tgl-breadcrumbs :items="breadcrumbs" />
      <geofence-actions
        v-if="$store.getters.getPermission('geofences_remove')"
      ></geofence-actions>
    </div>
    <div class="md-layout-item md-medium-size-100 md-size-100">
      <geofence> </geofence>
    </div>
  </div>
</template>

<script>
import TglBreadcrumbs from "@/components/UI/TglBreadcrumbs";
import Geofence from "@/components/GeoFence/Geofence";
import GeofenceActions from "@/components/GeoFence/Actions";

import { mapGetters } from "vuex";
export default {
  components: {
    TglBreadcrumbs,
    Geofence,
    GeofenceActions,
  },
  computed: {
    ...mapGetters(["getTheme"]),
    breadcrumbs() {
      if (this.$route.params.geofence) {
        return [
          {
            label: "Espacios de trabajo",
            disabled: false,
            to: { name: "workspace", params: { tab: 1 } },
          },
          {
            label: `Geocerca`,
            disabled: true,
            to: { name: "workspace", params: { tab: 1 } },
          },
        ];
      } else {
        return [
          {
            label: "Espacios de trabajo",
            disabled: false,
            to: { name: "workspace", params: { tab: 1 } },
          },
          {
            label: "Geocerca",
            disabled: true,
            to: { name: "workspace", params: { tab: 1 } },
          },
        ];
      }
    },
  },
};
</script>