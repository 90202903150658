<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn tile color="error" v-bind="attrs" v-on="on">
        <v-icon left>
          {{ deleteIcon }}
        </v-icon>
        {{ $t("buttons.delete") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline lighten-2">
        {{ $t("confirmations.areYouSure") }}
      </v-card-title>

      <v-card-text
        v-if="(deviceAlias || slotAlias) && !$route.params.eventListener"
      >
        {{
          $route.params.slot
            ? `${$t("confirmations.deleteSlot1")} ${slotAlias} ${$t(
                "confirmations.deleteSlot2"
              )} ${deviceAlias} ${$t("confirmations.deleteSlot3")}`
            : `${$t("confirmations.deleteDevice1")} ${deviceAlias} ${$t(
                "confirmations.deleteDevice2"
              )}`
        }}
      </v-card-text>
      <v-card-text v-else>
        {{ $t("confirmations.delete") }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> {{ $t("buttons.cancel") }} </v-btn>
        <v-btn
          color="primary"
          text
          @click="
            () => {
              deleteItem();
              dialog = false;
            }
          "
        >
          {{ $t("buttons.agree") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiDelete } from "@mdi/js";
export default {
  data: () => ({
    deleteIcon: mdiDelete,
    dialog: false,
    deviceAlias: "",
    slotAlias: "",
  }),
  props: {
    dispatch: { required: true, type: String },
    entity: { required: false, type: String, default: null },
    redirect: { type: Object, default: null },
  },
  mounted() {
    this.getItem();
  },
  methods: {
    deleteItem() {
      this.$store
        .dispatch(
          this.dispatch,
          this.entity ? this.$route.params[this.entity] : this.$route.params
        )
        .then(() => {
          this.$toast.success(this.$t("toast.deleteSuccess"));
          if (this.redirect !== null) this.$router.push(this.redirect);
        });
    },
    getItem() {
      if (this.$route.params.device) {
        this.$store
          .dispatch("device/getDevice", this.$route.params.device)
          .then((device) => {
            this.deviceAlias = device.alias;
          });
      }
      if (this.$route.params.slot) {
        this.$store
          .dispatch("slot/getSlot", this.$route.params)
          .then((slot) => {
            this.slotAlias = slot.alias;
          });
      }
    },
  },
};
</script>