<template>
  <div class="container-fluid p-2">
    <v-card class="mt-5 p-5">
      <div class="row">
        <div class="col-4">
          <p v-if="geofence"><b>Name:</b> {{ geofence.name }}</p>
          <p v-else><b>Name:</b></p>
        </div>
        <div class="col-8">
          <p v-if="geofence"><b>Description:</b> {{ geofence.description }}</p>
          <p v-else><b>Description:</b></p>
        </div>
      </div>
      <geofence-map-details
        v-if="geofence"
        :geofencePoints="geofence.geofence"
        :color="geofence.color"
      ></geofence-map-details>
    </v-card>
  </div>
</template>

<script>
import GeofenceMapDetails from "@/components/GeoFence/GeofenceMapDetails";
export default {
  components: { GeofenceMapDetails },
  name: "geofence-details",
  data() {
    return {
      geofence: null,
    };
  },
  methods: {
    getZone() {
      this.$store
        .dispatch("zone/getZone", this.$route.params.geofence)
        .then((data) => {
          this.geofence = data;
        })
        .catch((err) => console.error(err));
    },
  },
  mounted() {
    this.getZone();
  },
};
</script>

<style>
</style>