<template>
  <l-map
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    style="height: 500px"
    ref="map"
  >
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-polygon
      :lat-lngs="geofence"
      :weight="2"
      :opacity="1"
      :color="color"
      :fillColor="color"
    ></l-polygon>
  </l-map>
</template>
<script>
import "leaflet-search/src/leaflet-search";
import { LMap, LTileLayer, LPolygon } from "vue2-leaflet";

export default {
  name: "geofence-map-details",
  props: {
    color: {
      type: String,
      required: true,
    },
    geofencePoints: {
      type: Array,
      required: true,
    },
  },
  components: {
    LMap,
    LTileLayer,
    LPolygon,
  },
  data: () => ({
    zoom: 12,
    center: [-33.4513, -70.6653],
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    currentZoom: 11.5,
    currentCenter: [-33.4513, -70.6653],
    mapOptions: {
      zoomSnap: 0.5,
      doubleClickZoom: false,
    },
    geofence: [],
  }),
  methods: {
    beforeDestroy() {
      clearInterval(this.interval);
    },
  },
  mounted() {
    this.center = [this.geofencePoints[0][0], this.geofencePoints[0][1]];
    this.geofence = this.geofencePoints;
  },
};
</script>
<style lang="sass">
@import '@/assets/sass/partials/map.sass'
.leaflet-pane
  z-index: 1
</style>
