<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab @click="updateRoute('details')">{{$t('workspace.geofence.geofenceTab.detail')}}</v-tab>
      <v-tab
        @click="updateRoute('edit')"
        v-if="$store.getters.getPermission('geofences_edit')"
        >{{$t('workspace.geofence.geofenceTab.edit')}}</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <geofence-details></geofence-details>
      </v-tab-item>
      <v-tab-item>
        <geo-fence-form></geo-fence-form>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import GeofenceDetails from "@/components/GeoFence/GeofenceDetails";
import GeoFenceForm from "@/components/GeoFence/GeoFenceForm";

export default {
  name: "Geofence",
  components: {
    GeofenceDetails,
    GeoFenceForm,
  },
  data() {
    return {
      tab: null,
    };
  },
  watch: {},
  methods: {
    setTab(tabName) {
      switch (tabName) {
        case "details":
          this.tab = 0;
          break;
        case "edit":
          this.tab = 1;
          break;
        default:
          this.tab = 0;
          break;
      }
    },
    updateRoute(path) {
      this.$router.replace(`/geofence/${this.$route.params.geofence}/${path}`);
    },
  },
  mounted() {
    this.setTab(this.$route.params.tab);
  },
};
</script>

<style>
</style>